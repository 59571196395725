import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

export default new Router({
  mode: 'history',  
  routes: [
    // 你的路由配置
    {
      path: '/',
      name: 'home',
      component: () => import('@/components/Home')
    },
    {
      path: '/tutorial',
      name: 'Tutorial',
      component: () => import('@/components/Tutorial')
    },
    {
      path: '/live-questions',
      name: 'live-questions',
      component: () => import('@/components/LiveQuestions')
    },
    {
      path: '/obs-download',
      name: 'obsdownload',
      component: () => import('@/components/ObsDownload')
    },
  ]
});
