<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2024-11-13 13:32:47
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2024-11-13 14:08:56
 * @FilePath: \bobanlv_com\src\components\QrCodeComponent.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <!-- 使用 qrcode.vue 生成二维码 -->
    <qrcode-vue
      :value="qrcodeUrl"
      :size="100"
      level="M"
    />
  </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue'

export default {
  components: {
    QrcodeVue
  },
  props: {
    qrcodeUrl: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>
/* 可以根据需要调整二维码的样式 */
</style>
