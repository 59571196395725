<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2024-11-11 09:43:35
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2024-11-13 16:09:32
 * @FilePath: \bobanlv_com\src\App.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div id="app">
    <Header />
    <main>
      <router-view></router-view>
    </main>
  </div>
</template>

<script>
import Header from './components/Header.vue';

export default {
  data:{
    user_info:null
  },
  components: {
    Header,
  },
  
};
</script>

<style>
/* 确保 html 和 body 填满整个屏幕 */
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

#app {
  width: 100%;
  background-image: url(./assets/网页.png);
  background-size: cover; /* 背景图片覆盖整个屏幕 */
  background-position: center center; /* 保证背景图居中 */
  background-attachment: fixed; /* 固定背景图，避免滚动时背景图移动 */
  display: flex;
  flex-direction: column; /* 保证子元素垂直排列 */
  min-height: 100vh;  /* 确保背景图总是占满整个视口 */
  justify-content: flex-start;  /* 内容在顶部对齐 */
}

main {
  flex: 1; /* 使 main 部分填满剩余空间 */
}
</style>
